body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.space {
  flex-grow: 1;
}

.logo_menu {
  width: 22px;
  height: 22px;
  background-image: url("./images/green_table_logo_small.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 18px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.menu:hover .logo_menu {
  width: 140px;
  height: 22px;
  background-image: url("./images/green_table_logo.svg");  
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 15px;
  margin-top: 24px;
  margin-bottom: 15px;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.menu:hover {
  width: 256px;
}

.menuItem {
  display: none;
}

.menu:hover .menuItem {
  display: block;
  /* background-color: rgba(0, 0, 0, 0.04) */;
}

.navigationMenuItem,
.navigationMenuItem:active,
.navigationMenuItem:hover,
.navigationMenuItem:visited {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.tableLink {
  color: rgba(0,0,0,0.87);
  text-decoration: none;
}